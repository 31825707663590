
import { Component, Mixins } from 'vue-property-decorator';

// Node module imports
import { View } from 'client-website-ts-library/plugins';

import { ListingFilter, ProfileFilter } from 'client-website-ts-library/filters';

import {
  ListingStatus,
  Office,
  MapMarker,
  WebsiteLevel,
  Profile,
  WebsiteProfileDisplayType,
  ListingCategory,
  Listing,
} from 'client-website-ts-library/types';

import {
  API,
  Config,
  Logger,
  LogLevel,
} from 'client-website-ts-library/services';

import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue';
import { ItemCyclerSlide, ItemCyclerImageSlide } from '../app_code/ItemCycler';
import ItemCycler from '../components/Cycler/ItemCycler.vue';
import Listings from '../components/Listings.vue';
import ListingSearch from '../components/ListingSearch.vue';
import PhotoRow from '../components/Layout/PhotoRow.vue';
import Form from '../components/Forms/Form.vue';
import GridNavigation from '../components/UI/GridNavigation.vue';
import GridNavigationItem from '../components/UI/GridNavigationItem.vue';
import SideBySide from '../components/Layout/SideBySide.vue';

@Component({
  components: {
    ItemCycler,
    Listings,
    ListingSearch,
    PhotoRow,
    Form,
    GridNavigation,
    GridNavigationItem,
    SideBySide,
    ListingCardCycler,
  },
})
export default class Home extends Mixins(View) {
  private office: Office | null = null;

  private salesListings: Listing[] | null = null;

  private profiles: Profile[] | null = null;

  private cyclerImages: ItemCyclerSlide[] = [
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/1',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/2',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/3',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
    new ItemCyclerImageSlide({
      source: '/assets/images/home/slider/4',
      formats: ['avif', 'webp', 'jpg'],
      fallback: 'jpg',
    }),
  ];

  private listingSalesFilter: ListingFilter = new ListingFilter({
    Categories: [ListingCategory.ResidentialSale, ListingCategory.ResidentialLand, ListingCategory.Commercial, ListingCategory.CommercialLand, ListingCategory.Business],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    IsFeatured: true,
    PageSize: 1000,
  });

  mounted() {
    this.loadOffice();
    this.loadProfiles();
    this.loadSalesListings();
  }

  loadProfiles(): void {
    API.Profiles.Search(new ProfileFilter()).then((profiles) => {
      this.profiles = profiles.filter((p) => p.DisplayType !== WebsiteProfileDisplayType.Principal);
    });
  }

  loadOffice(): void {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }

  loadSalesListings(): void {
    API.Listings.Search(this.listingSalesFilter).then((listings) => {
      this.salesListings = listings.Items;
    });
  }
}
