
import LazyImage from '../UI/LazyImage.vue';

export default {
  components: { LazyImage },
  props: {
    images: Array,
  },
  methods: {
  },
};
